
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"06c8564c2ccdbe7a25b2baf566a762b9d18f27ee":"$$ACTION_0","e05b197f7d185bd952c6c97d531d31bdc28b1fed":"contactSupportAction"} */ export var contactSupportAction = createServerReference("e05b197f7d185bd952c6c97d531d31bdc28b1fed");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

